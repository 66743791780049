<template>
  <td :colspan="headers.length">
    <v-row v-if="item.detail_frais">
      <!--  Détail des frais -->
      <v-col
        cols="12"
        :lg="getFactureColSize(item.detail_frais)"
      >
        <v-card
          tile
        >
          <v-toolbar
            color="primary"
            dark
          >
            <v-icon
              class="mr-2"
              x-large
            >
              mdi-currency-usd
            </v-icon>

            <v-toolbar-title>{{ $t('contract.bill.Details_costs').toUpperCase() }}</v-toolbar-title>
          </v-toolbar>

          <v-list>
            <!--  FRAIS VOIX [DE BASE]-->
            <template v-if="item.detail_frais.frais.frais_voix_de_base">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>{{ $t('contract.bill.Voice_charges_basic').toUpperCase() }} :</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  {{ item.detail_frais.frais.frais_voix_de_base }}
                </v-list-item-action>
              </v-list-item>

              <v-divider />
            </template>

            <!--  FRAIS VOIX [UTILISATION]-->
            <template v-if="item.detail_frais.frais.frais_voix_utilisation">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>{{ $t('contract.bill.Voice_usage_costs').toUpperCase() }} :</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  {{ item.detail_frais.frais.frais_voix_utilisation }}
                </v-list-item-action>
              </v-list-item>

              <v-divider />
            </template>

            <!--  FRAIS DONNÉES ET OPTIONS [ DE BASE ]-->
            <template v-if="item.detail_frais.frais.frais_donnees_et_options_de_base">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>{{ $t('contract.bill.Data_charges_and_options_basic').toUpperCase() }} :</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  {{ item.detail_frais.frais.frais_donnees_et_options_de_base }}
                </v-list-item-action>
              </v-list-item>

              <v-divider />
            </template>

            <!--  FRAIS DONNÉES ET OPTIONS [UTILISATION]-->
            <template v-if="item.detail_frais.frais.frais_donnees_et_options_utilisation">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>{{ $t('contract.bill.Data_charges_and_options_use').toUpperCase() }} :</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  {{ item.detail_frais.frais.frais_donnees_et_options_utilisation }}$
                </v-list-item-action>
              </v-list-item>

              <v-divider />
            </template>

            <!--  FRAIS INTERURBAIN-->
            <template v-if="item.detail_frais.frais.frais_interurbain">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    {{ $t('contract.bill.Long_distance_charges').toUpperCase() }} :
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  {{ item.detail_frais.frais.frais_interurbain }}
                </v-list-item-action>
              </v-list-item>

              <v-divider />
            </template>

            <!--  FRAIS INTINÉRANCE-->
            <template v-if="item.detail_frais.frais.frais_itinerance">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    {{ $t('contract.bill.Roaming_charges').toUpperCase() }} :
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  {{ item.detail_frais.frais.frais_itinerance }}
                </v-list-item-action>
              </v-list-item>

              <v-divider />
            </template>

            <!--  AJUSTEMENT FRAIS FOURNISSEUR-->
            <template v-if="item.detail_frais.frais.ajustement_fournisseur">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>{{ $t('contract.bill.Supplier_fee_adjustment').toUpperCase() }} :</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  {{ item.detail_frais.frais.ajustement_fournisseur }}
                </v-list-item-action>
              </v-list-item>

              <v-divider />
            </template>

            <!--  FRAIS DE CREDIT FOURNISSEUR-->
            <template v-if="item.detail_frais.frais.frais_et_credit_fournisseur">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>{{ $t('contract.bill.Fees_and_supplier_credit').toUpperCase() }} :</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  {{ item.detail_frais.frais.frais_et_credit_fournisseur }}
                </v-list-item-action>
              </v-list-item>

              <v-divider />
            </template>

            <!--  CREDIT AJUSTEMENT-->
            <template v-if="item.detail_frais.frais.credit_ajustement">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>{{ $t('contract.bill.Adjustment_credit').toUpperCase() }} :</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  {{ item.detail_frais.frais.credit_ajustement }}
                </v-list-item-action>
              </v-list-item>

              <v-divider />
            </template>

            <!--  AJUSTEMENT_GST (TPS)-->
            <template v-if="item.detail_frais.frais.ajustement_gst">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>{{ $t('contract.bill.Adjustment_gst').toUpperCase() }} :</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  {{ item.detail_frais.frais.ajustement_gst }}
                </v-list-item-action>
              </v-list-item>

              <v-divider />
            </template>

            <!--  AJUSTEMENT_QST (TVQ)-->
            <template v-if="item.detail_frais.frais.ajustement_qst">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>{{ $t('contract.bill.Adjustment_qst').toUpperCase() }} :</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  {{ item.detail_frais.frais.ajustement_qst }}
                </v-list-item-action>
              </v-list-item>

              <v-divider />
            </template>

            <!--  CREDIT VOIX DATA AUTRE-->
            <template v-if="item.detail_frais.frais.frais_voix_data_autre">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>{{ $t('contract.bill.Fees_voice_data_other').toUpperCase() }} :</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  {{ item.detail_frais.frais.frais_voix_data_autre }}
                </v-list-item-action>
              </v-list-item>

              <v-divider />
            </template>

            <!--  AUTRE FRAIS-->
            <template v-if="item.frais_autre">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>{{ $t('contract.bill.Other_charge').toUpperCase() }} :</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  {{ item.frais_autre.toFixed(2) }}$
                </v-list-item-action>
              </v-list-item>

              <v-divider />
            </template>

            <!--  FRAIS DE RETARD-->
            <template v-if="item.frais_retard">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>{{ $t('contract.bill.Late_fee').toUpperCase() }} :</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  {{ item.frais_retard.toFixed(2) }}$
                </v-list-item-action>
              </v-list-item>

              <v-divider />
            </template>

            <!--  ADMIN-->
            <template v-if="item.frais_administration">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>{{ $t('contract.bill.Admin').toUpperCase() }} :</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  {{ item.frais_administration.toFixed(2) }}$
                </v-list-item-action>
              </v-list-item>

              <v-divider />
            </template>

            <!--  CREDIT APPLIQUE-->
            <template v-if="item.credit_applique">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle class="red--text">
                    {{ $t('contract.bill.Credit_applied').toUpperCase() }} :
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <span
                    class="text-bold red--text"
                  >
                    {{ item.credit_applique.toFixed(2) }}$
                  </span>
                </v-list-item-action>
              </v-list-item>

              <v-divider />
            </template>

            <!--  SOUS TOTAL-->
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-bold">
                  {{ $t('contract.bill.Total_before_tax').toUpperCase() }} :
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <span
                  class="text-bold"
                >
                  {{ item.montant_at.toFixed(2) }}$
                </span>
              </v-list-item-action>
            </v-list-item>

            <v-divider />

            <!--  FRAIS AJUSTEMENT-->
            <template v-if="item.frais_ajustement">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle class="red--text text-bold">
                    {{ $t('Adjustment_fee').toUpperCase() }} :
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class="red--text text-bold">
                  {{ item.frais_ajustement.toFixed(2) }}$
                </v-list-item-action>
              </v-list-item>

              <v-divider />

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle class="red--text text-bold">
                    {{ $t('Adjusted_total').toUpperCase() }} :
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class="red--text text-bold">
                  {{ calculTotalAjuste(item.frais_ajustement, item.montant_at).toFixed(2) }}$
                </v-list-item-action>
              </v-list-item>

              <v-divider />
            </template>

            <!--  TPS-->
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  TPS :
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                {{ item.tps.toFixed(2) }}$
              </v-list-item-action>
            </v-list-item>

            <v-divider />

            <!--  TVQ-->
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  TVQ :
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                {{ item.tvq.toFixed(2) }}$
              </v-list-item-action>
            </v-list-item>

            <v-divider />

            <!--  TOTAL-->
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-bold">
                  TOTAL :
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <span
                  class="text-bold"
                >
                  {{ item.montant_total.toFixed(2) }}$
                </span>
              </v-list-item-action>
            </v-list-item>
            <v-divider />

            <!--  CREDIT RESIDUEL-->
            <template v-if="item.credit_residuel">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle class="red--text">
                    {{ $t('contract.bill.Residual_credit').toUpperCase() }} :
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class="red--text text-bold">
                  {{ item.credit_residuel.toFixed(2) }}$
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
        </v-card>
      </v-col>

      <!--  Détail des appels -->
      <v-col
        v-if="item.detail_frais.appels.minutes_jour"
        cols="12"
        :lg="getFactureColSize(item.detail_frais)"
      >
        <v-card
          tile
        >
          <v-toolbar
            color="primary"
            dark
          >
            <v-icon
              class="mr-2"
              x-large
            >
              mdi-phone
            </v-icon>

            <v-toolbar-title>{{ $t('contract.bill.Call_details').toUpperCase() }}</v-toolbar-title>

            <v-spacer />

            <v-tooltip
              top
            >
              <template #activator="{ on, attrs }">
                <span
                  class="pointer"
                  @click="openDialogdialogCallDetails(item.id)"
                >{{ $t('contract.bill.Display_details') }}</span>
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="openDialogdialogCallDetails(item.id)"
                >
                  <v-icon>mdi-file-table</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('contract.calls.Call_list') }}</span>
            </v-tooltip>
          </v-toolbar>

          <v-list>
            <!--  MINUTES [ JOURS ]-->
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t('contract.calls.Days').toUpperCase() }} :</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                {{ item.detail_frais.appels.minutes_jour }}
              </v-list-item-action>
            </v-list-item>

            <v-divider />

            <!--  MINUTES [ SOIRÉE ]-->
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t('contract.calls.Evening').toUpperCase() }} :</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                {{ item.detail_frais.appels.minutes_soir }}
              </v-list-item-action>
            </v-list-item>

            <v-divider />

            <!--  MINUTES [ FIN DE SEMAINE ]-->
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t('contract.calls.Weekend').toUpperCase() }} :</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                {{ item.detail_frais.appels.minutes_fin_de_semaine }}
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>

      <!--  Détail du data -->
      <v-col
        v-if="item.detail_frais.data.length"
        cols="12"
        :lg="getFactureColSize(item.detail_frais)"
      >
        <v-card
          tile
        >
          <v-toolbar
            color="primary"
            dark
          >
            <v-icon
              class="mr-2"
              x-large
            >
              mdi-wifi
            </v-icon>

            <v-icon
              class="mr-2"
              x-large
            >
              mdi-chat-outline
            </v-icon>

            <v-toolbar-title>DATA</v-toolbar-title>
          </v-toolbar>

          <v-list>
            <div
              v-for="(dataItem, index) in item.detail_frais.data"
              :key="index"
            >
              <v-list-item v-if="dataItem.key">
                <v-list-item-content>
                  <v-list-item-title>{{ $t(`contract.data.${dataItem.key}`).toUpperCase() }} :</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  {{ dataItem.value }}
                </v-list-item-action>
              </v-list-item>

              <!--  On ne fait pas apparaître le dernier divider-->
              <v-divider v-if="(index + 1) !== item.detail_frais.data.length" />
            </div>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <!--  Dialog détails des appels-->
    <v-dialog
      v-model="dialogCallDetails"
      max-width="1000"
    >
      <v-card>
        <v-card-title>
          {{ $t('contract.calls.Call_list') }}

          <v-spacer />

          <v-simple-table>
            <template #default>
              <thead>
                <tr>
                  <th class="text-left">
                    {{ $t('contract.calls_detail.Date_hour').toUpperCase() }}
                  </th>
                  <th class="text-left">
                    {{ $t('contract.calls_detail.Origin').toUpperCase() }}
                  </th>
                  <th class="text-left">
                    {{ $t('contract.calls_detail.Destination_no').toUpperCase() }}
                  </th>
                  <th class="text-left">
                    {{ $t('contract.calls_detail.Location').toUpperCase() }}
                  </th>
                  <th class="text-left">
                    {{ $t('contract.calls_detail.Duration').toUpperCase() }}
                  </th>
                  <th class="text-left">
                    {{ $t('contract.calls_detail.Cost').toUpperCase() }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="itemCall in callsDetail"
                  :key="itemCall.name"
                >
                  <td class="text-left">
                    {{ itemCall.date }} {{ itemCall.heure }}
                  </td>
                  <td class="text-left">
                    {{ itemCall.de }}
                  </td>
                  <td class="text-left">
                    {{ itemCall.numero_appele }}
                  </td>
                  <td class="text-left">
                    {{ itemCall.a }}
                  </td>
                  <td class="text-left">
                    {{ itemCall.duree }}
                  </td>
                  <td class="text-left">
                    {{ itemCall.total }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-icon
            aria-label="Close"
            @click="dialogCallDetails = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text class="body-1 text-center">
          <v-row>
            <v-col
              cols="12"
            >
              <div :id="printMe">
                <div v-html="recuFormat" />
              </div>
            </v-col>
          </v-row>

          <v-btn
            class="mt-2 ml-4"
            color="info"
            depressed
            default
            rounded
            @click="dialogCallDetails = false"
          >
            {{ $t('Close') }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </td>
</template>
<script>
  // Service
  import FacturesService from '@/services/01Cell/FacturesService'
  // Mixins
  import { FacturePaiement } from '@/mixins/Facture/FacturePaiement'

  export default {
    name: 'FactureComponentDetails',
    mixins: [FacturePaiement],
    props: {
      headers: Array,
      item: Object,
    },
    data () {
      return {
        dialogCallDetails: false,
        callsDetail: [],
        printMe: null,
      }
    },

    methods: {
      calculTotalAjuste (ajustement, sousTotal) {
        return (parseFloat(ajustement, 10) + parseFloat(sousTotal, 10)).toFixed(2)
      },
      getFactureColSize (item) {
        // 4 6 12
        let col = 0
        if (item.frais.frais_voix_de_base) col++
        if (item.appels.minutes_jour) col++
        if (item.data.length) col++
        return 12 / col
      },
      async openDialogdialogCallDetails (factureId) {
        this.printMe = `printMe_${factureId}`
        // Start Spinner
        this.$loading.show()
        try {
          this.callsDetail = await FacturesService.callsDetail(factureId)
          // Stop Spinner
          this.$loading.hide()
          // Show dialog
          this.dialogCallDetails = true
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
    },
  }
</script>

<style scoped>
  .text-bold {
    font-weight: bold;
  }

  .pointer {
    cursor: pointer;
  }
</style>
